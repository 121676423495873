import { STATUS_CODE } from "../config/constants";
import { getClients } from "../services/administration";

const defautlState = {
  clients: [],
  currentClient: null,
};

const administration = {
  state: defautlState,
  reducers: {
    SET_CLIENTS: (state, payload) => ({
      ...state,
      ...payload,
    }),
    SELECT_CLIENT: (state, payload) => ({
      ...state,
      currentClient: payload,
    }),
  },
  effects: {
    selectClient({ userId, callback }, state) {
      const currentClient = state.administration.clients?.find(
        (x) => x.userId === userId
      );
      this.SELECT_CLIENT(currentClient);
      callback && callback();
    },
    async loadClients() {
      try {
        const response = await getClients();
        const resData = response.data;
        if (resData.statusCode !== STATUS_CODE.SUCCESS) {
          Promise.reject(resData.message);
        }
        this.SET_CLIENTS({
          clients: resData.data,
        });
      } catch (error) {
        this.SET_CLIENTS(defautlState);
      }
    },
  },
};

export default administration;
