import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import instance from "../../services/instance";
import { sortNumber, sortString, sortBool } from "../../utils/general";
import SearchTableFilter from "./SearchTableFilter";
import DateTableFilter from "./DateTableFilter";
import { show, showError } from "../../utils/message";
import { TYPE_MESSAGE, STATUS_CODE, FILTER_TYPE } from "../../config/constants";

const typeSorter = {
  string: sortString,
  number: sortNumber,
  bool: sortBool,
};

const resolveRender = (field) => {
  if (
    ["radio", "select", "cascader"].indexOf(field.type) !== 0 &&
    !field.render
  ) {
    field.render = (text, record) => {
      return field.textKey ? record[field.textKey] : field.options[text];
    };
  } else if (field.type === "bool" && !field.render) {
    field.render = (text, record) =>
      record[field.key] ? field.options["true"] : field.options["false"];
  }
  return field.render;
};

const FilterIcon = (filtered) => (
  <SearchOutlined
    style={{
      color: filtered ? "white" : "black",
      background: filtered ? "#1890ff" : undefined,
    }}
  />
);

FilterIcon.propTypes = {
  filtered: PropTypes.bool,
};

const resolveFilter = (field, selectList) => {
  if (field.filter === true) {
    switch (field.type) {
      case "date":
      case "number":
      case "cascader":
      case "select":
      case "string": {
        if (
          field.filterType == FILTER_TYPE.options &&
          selectList != null &&
          selectList.hasOwnProperty(field.textKey)
        ) {
          const keysOptions = Object.keys(selectList[field.textKey]);
          return {
            filters: keysOptions.map((value) => ({
              value,
              text: selectList[field.textKey][value],
            })),
            filterIcon: FilterIcon,
            filterSearch: true,
            filterMultiple: false,
            onFilter: (value, record) => `${record[field.key]}` === value,
          };
        }
        return {
          filterDropdown:
            field.type === "date" ? DateTableFilter : SearchTableFilter,
          filterIcon: FilterIcon,
          onFilter: (value, record) => {
            if (field.render) {
              return (
                record[field.columnKey || field.key] &&
                field
                  .render(record[field.columnKey || field.key], record)
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase())
              );
            }
            return (
              record[field.columnKey || field.key] &&
              record[field.columnKey || field.key]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
            );
          },
        };
      }
      case "radio": {
        const keysOptions = Object.keys(field.options);
        return {
          filters: keysOptions.map((value) => ({
            value,
            text: field.options[value],
          })),
          filterMultiple: keysOptions.length > 2,
          onFilter: (value, record) =>
            record[field.key].toString().indexOf(value) === 0,
        };
      }
      case "bool": {
        if (field.features) {
          return {
            filters: [
              {
                value: "1",
                text: field.features.checkedChildren,
              },
              {
                value: "0",
                text: field.features.unCheckedChildren,
              },
            ],
            filterIcon: FilterIcon,
            filterMultiple: false,
            onFilter: (value, record) => `${record[field.key]}` === value,
          };
        }

        const keysOptions = Object.keys(field.options);
        return {
          filters: keysOptions.map((value) => ({
            value,
            text: field.options[value],
          })),
          filterMultiple: false,
          filterIcon: FilterIcon,
          onFilter: (value, record) => record[field.key].toString() === value,
        };
      }
      default: {
        return {};
      }
    }
  }
  return undefined;
};

const fieldsToColumns = (fields, selectList) => {
  return fields
    .filter(
      (field) =>
        !(field.hasOwnProperty("hidden") && field.hidden.includes("column"))
    )
    .map((field) => ({
      title: field.title || "",
      key: field.key,
      dataIndex: field.key,
      sorter:
        field.sorter !== true
          ? false
          : typeSorter[field.type]
          ? typeSorter[field.type](field.textKey || field.key)
          : typeSorter.string(field.textKey || field.key),
      render: resolveRender(field),
      ...(field.columnStyle || {}),
      ...(resolveFilter(field, selectList) || {}),
    }));
};

const validateDependency = ({
  field,
  allValues,
  fields,
  keys = Object.keys(allValues),
  options,
}) => {
  if (
    field.dependencies &&
    field.dependencies.fields.filter((dependency) => keys.includes(dependency))
      .length > 0
  ) {
    if (
      (field.dependencies.fields || keys).reduce(
        (allHaveValues, current) => allHaveValues && !!allValues[current],
        true
      )
    ) {
      return (
        field.dependencies &&
        field.dependencies.onChange(allValues, fields, options)
      );
    }
  }
};

export function useCrudList({ initialLoad = true, ...conf }) {
  const setNameColumns = (item) => {
    const items = {};
    conf.fields
      .filter(
        (field) =>
          field.configOptions && typeof field.configOptions.url === "string"
      )
      .forEach((field) => {
        if (selectList[field.textKey]) {
          items[field.textKey] =
            selectList[field.textKey][item[field.key]] || item[field.textKey];
        }
      });

    return items;
  };

  const loadData = (dataT) =>
    dataT == null
      ? []
      : dataT.map((item, i) => ({
          key: i,
          ...item,
          ...setNameColumns(item),
        }));

  const [dataSource, setDataSource] = useState(loadData(conf.initialData));
  const [loading, setLoading] = useState(false);
  const [selectList, setSelectList] = useState({});
  let columns = useMemo(
    () => fieldsToColumns(conf.fields, selectList),
    [conf.fields, selectList]
  );

  const reload = (params) => {
    conf.params = params;
    load();
  };

  const loadKeyNames = async () => {
    const promises = { all: [], keys: {} };
    let i = 0;
    conf.fields.forEach((field) => {
      if (field.configOptions && typeof field.configOptions.url === "string") {
        const { url, method = "get" } = field.configOptions;
        promises.all.push(instance[method](url));
        promises.keys[field.textKey] = i;
        i += 1;
      }
    });

    if (promises.all.length === 0) {
      setSelectList(null);
      return false;
    }

    const lists = {};
    const responses = await axios.all(promises.all);

    conf.fields
      .filter(
        (field) =>
          field.configOptions && typeof field.configOptions.url === "string"
      )
      .forEach((field, i) => {
        const data =
          promises.keys[field.textKey] >= 0
            ? (responses[promises.keys[field.textKey]] &&
              field.configOptions.map
                ? responses[promises.keys[field.textKey]].data
                  ? responses[promises.keys[field.textKey]].data.data.reduce(
                      (items, item) => ({
                        ...items,
                        ...field.configOptions.map(item),
                      }),
                      {}
                    )
                  : {}
                : responses[promises.keys[field.textKey]].data) || []
            : field.options || [];
        lists[field.textKey] = data;
      });
    setSelectList(lists);
  };

  const load = () => {
    setLoading(true);
    let request = () => instance.get(conf.getList);
    if (conf.params) {
      request = () =>
        instance.get(conf.getList, {
          params: conf.params,
        });
    }

    request()
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.statusCode !== STATUS_CODE.SUCCESS) {
          Promise.reject(resData.message);
        }
        setDataSource(loadData(resData.data));
      })
      .catch((e) => {
        setLoading(false);
        console.log("ERROR", e);
      });
  };

  useEffect(() => {
    loadKeyNames();
  }, []);

  useEffect(() => {
    if (conf.initialData == null) {
      setLoading(true);
      if (initialLoad) {
        load();
      }
    }
  }, [selectList]);

  const onDelete = (key, callback = () => {}) => {
    setLoading(true);
    instance
      .delete(`${conf.delete}/${key}`, { params: { [conf.keyName]: key } })
      .then((response) => {
        callback();
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        showError("¡No se puede eliminar el regtistro!");
        showError("¡El registro tiene información asociada!");
      });
  };

  const cloneRow = (recordToClone, uniqueColumns = [], callback) => {
    setLoading(true);

    const index = dataSource.indexOf(recordToClone);

    const newRecord = JSON.parse(JSON.stringify(recordToClone));
    newRecord.id = newRecord.key = new Date().valueOf();

    uniqueColumns.forEach((key) => {
      newRecord[key] += "-COPY";
    });

    dataSource.splice(index, 0, newRecord);
    setDataSource([...dataSource]);

    setTimeout(() => {
      setLoading(false);
    }, 1000);

    callback && callback();
  };

  return {
    columns,
    dataSource,
    onDelete,
    loading,
    reload,
    cloneRow,
    setLoading,
    deepReload: loadKeyNames,
  };
}

export function useCrudForm({
  conf,
  key,
  preloaders = async () => {},
  modelData = {},
  dependencies,
  setModelData = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const [_data, _setData] = useState({});
  const [fields, setFields] = useState(
    conf.fields.filter(
      (field) =>
        !(field.hasOwnProperty("hidden") && field.hidden.includes("form"))
    )
  );

  const setValue = (field, data) => {
    switch (field.type) {
      case "date": {
        return data[field.key] != null ? moment(data[field.key]) : null;
      }
      case "time": {
        return data[field.key] != null
          ? moment(data[field.key], "HH:mm")
          : null;
      }
      case "rangedate":
        return data[field.key] != null
          ? data[field.key].map((date) => {
              return moment(date);
            })
          : [];
      case "bool":
      case "cascader":
      case "number":
      case "slider":
        return data[field.key];
      case "checkbox":
      case "select":
      case "tag":
        if (
          field.type === "checkbox" ||
          field.hasOwnProperty("mode") ||
          field.type === "tag"
        ) {
          return data[field.key] != null
            ? data[field.key].map((chk) => {
                return chk[field.textKey]
                  ? chk[field.textKey].toString()
                  : chk.toString();
              })
            : null;
        } else {
          return data[field.key] ? data[field.key].toString() : "";
        }
      case "file":
        return (data[field.key] || []).map((e) => ({
          ...e,
          url: `${process.env.REACT_APP_BASE}${e.url}`,
        }));
      default:
        return data[field.key] ? data[field.key].toString() : "";
    }
  };

  useEffect(() => {
    if (dependencies) {
      setLoading(true);
      (async () => {
        const { url } = dependencies[0];
        const response = await instance.get(url);
        const options = await response.data[conf.data];
        const { key, map } = dependencies[0];
        const _field = fields.filter((e) => e.key === key);
        _field[0].options = options.reduce(
          (items, item) => ({
            ...items,
            ...map(item),
          }),
          {}
        );
        setLoading(false);
      })();
    }
  }, [dependencies]);

  useEffect(() => {
    let isEditing = false;
    if (key || Object.keys(modelData).length > 0) {
      isEditing = true;
    }

    async function init() {
      try {
        setLoading(true);
        let valuesFields = {};
        const promises = { all: [], keys: {} };
        let i = 0;
        fields.forEach((field) => {
          if (
            field.configOptions &&
            typeof field.configOptions.url === "string"
          ) {
            const { url, method = "get" } = field.configOptions;
            promises.all.push(instance[method](url));
            promises.keys[field.key] = i;
            i += 1;
          }
        });

        const responses = await axios.all(promises.all);

        const setFieldsValues = (data) => {
          fields.forEach((field) => {
            valuesFields[field.key] = {
              ...validateDependency({ field, allValues: data, fields }),
              value: setValue(field, data),
            };
          });
        };

        if (key) {
          const response = await instance.get(`${conf.getByKey}/${key}`, {
            params: { [conf.keyName || "key"]: key },
          });

          const info = response.data;
          if (info.statusCode !== STATUS_CODE.SUCCESS) {
            Promise.reject(info.message);
          }

          _setData(info.data);
          setModelData(info.data);
          modelData = info.data;
        }
        setFieldsValues(modelData);

        const getOptions = (field) =>
          promises.keys[field.key] >= 0
            ? (responses[promises.keys[field.key]] && field.configOptions.map
                ? responses[promises.keys[field.key]].data
                  ? responses[promises.keys[field.key]].data.data.reduce(
                      (items, item) => ({
                        ...items,
                        ...field.configOptions.map(item),
                      }),
                      {}
                    )
                  : {}
                : responses[promises.keys[field.key]].data) || {}
            : field.options || {};

        const managementFieldsIsEditing = (field) => {
          if (isEditing) {
            if (field?.hideWhenEditingForm) {
              field.formFeatures = {
                ...field.formFeatures,
                hidden: field.hideWhenEditingForm,
              };
              field.rules
                .filter((x) => x.required)
                .map((x) => (x.required = false));
            }
            field.disabledWhenEditingForm &&
              (field.features = {
                ...field.features,
                disabled:
                  typeof field.disabledWhenEditingForm === "function"
                    ? field.disabledWhenEditingForm(modelData)
                    : field.disabledWhenEditingForm,
              });
          } else {
            if (field?.hideWhenEditingForm) {
              field.rules
                .filter((x) => x.required)
                .map((x) => (x.required = true));
              delete (field?.formFeatures).hidden;
            }
            if (field?.disabledWhenEditingForm && field?.features) {
              delete (field?.features).disabled;
            }
          }
        };

        setFields(
          fields.map((field) => {
            const options = getOptions(field);
            const dependenciesOptions = field.dependencies
              ? getOptions(
                  fields.find((e) => e.key === field.dependencies.fields[0]) ||
                    {}
                )
              : {};
            managementFieldsIsEditing(field);
            return {
              ...field,
              ...(valuesFields[field.key] || {}),
              options,
              ...validateDependency({
                field,
                allValues: modelData,
                fields,
                options: dependenciesOptions,
              }),
            };
          })
        );
      } catch (e) {
        console.log("EXCEPTION", e.toString());
      }
      setLoading(false);
    }
    init();
    // .then(() => {
    //   console.log("PASO POR AQUI", _data);
    //   setModelData && setModelData(_data);
    // });
    // eslint-disable-next-line
  }, [key]);

  const onSubmit = (values, callback = () => {}) => {
    setLoading(true);

    let service = () =>
      instance.post(conf.post, {
        ...values,
        [conf.keyName || "key"]: key || undefined,
      });

    if (key) {
      service = () =>
        instance.put(`${conf.put}/${key}`, {
          ...values,
          [conf.keyName || "key"]: key || undefined,
        });
    }

    service()
      .then((response) => {
        callback(response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        show(
          err.type
            ? err
            : {
                type: TYPE_MESSAGE.Error,
                message: err.toString(),
              }
        );
      });
  };

  const onValuesChanged = (props, changedValues, allValues) => {
    const keys = Object.keys(changedValues);
    setFields(
      fields.map((field) => {
        return {
          ...field,
          ...validateDependency({ field, allValues, fields, keys }),
          value: allValues[field.key],
        };
      })
    );
  };

  return { fields, onSubmit, loading, onValuesChanged, data: _data };
}
