import Instance from "./instance";

const URLs = {
  clients: "administration/clients",
  users: "options/users",
  downloadFreeChargingUsers: "freeCharging/download",
  usersByCreatedAt: "administration/newUsers",
  cities: "options/allCities",
};

export const getCities = () => Instance.get(URLs.cities);
export const getClients = () => Instance.get(URLs.clients);
export const getUserService = () => Instance.get(URLs.users);
export const usersByCreatedAtService = ({ dateFrom, dateTo }) =>
  Instance.get(URLs.usersByCreatedAt, {
    params: {
      dateFrom,
      dateTo,
    },
  });

export const downloadFreeChargingUsers = () =>
  Instance({
    url: URLs.downloadFreeChargingUsers, //your url
    method: "GET",
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "file.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  });

export const downloadUserChargingService = (params) =>
  Instance({
    url: URLs.report,
    params,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "file.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
