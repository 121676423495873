import React, { useState, useEffect } from "react";
import io from "socket.io-client";

import { SOCKET_URL } from "../../config/constants";

const socket = io(SOCKET_URL);

export function useChargerSocket({ observer }) {
  useEffect(() => {
    handlers();
    return () => {
      socket.disconnect();
    };
  }, []);

  const handlers = () => {
    socket.on("client:chargerTurnedOFF", (res) => observer(res));
    socket.on("client:chargerTurnedON", (res) => {
      observer(res);
    });

    socket.on("server:actions", (res) => observer(res));
    socket.on("server:error", (res) => observer(res));
    socket.on("server:rpc:desconnected", (res) => observer(res));
    socket.on("server:charging:end", (res) => observer(res));

    socket.on("server:pqrs:created", (res) => observer(res));
    socket.on("server:pqrs:answered", (res) => observer(res));

    socket.on("server:station:enabled:notification", (res) => observer(res));
    socket.on("server:station:disabled:notification", (res) => observer(res));

    socket.on("server:rpc:desconnected:notification", (res) => observer(res));

    socket.on("server:user:created", (res) => observer(res));
    socket.on("server:paymentPlatform:changed", (res) => observer(res));
    socket.on("server:smsProvider:changed", (res) => observer(res));

    socket.on("disconnect", (reason) => {
      if (reason === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
      // else the socket will automatically try to reconnect
    });

    socket.on("connect_error", (error) => {
      console.log("SOCKET ERROR", error);
    });
  };

  const reconnect = () => socket.connected == false && socket.connect();

  const activeChargerRequest = ({ token, machineID, relayID, chargerID }) => {
    reconnect();

    socket.emit("admin:turnOnChargerRequest", {
      token,
      machineID,
      relayID,
      chargerID,
      transactionId: String(new Date().valueOf()),
    });
  };

  const executeRPCCommand = ({ action, token, rpcId, description }) => {
    reconnect();
    socket.emit("client:actions", {
      action,
      token,
      rpcId,
      description,
    });
  };

  const disableChargerRequest = ({
    token,
    machineID,
    transactionId,
    chargerID,
    relayID,
  }) => {
    reconnect();
    socket.emit("admin:turnOFFChargerRequest", {
      relayID,
      token,
      machineID,
      chargerID,
      transactionId,
    });
  };

  return { activeChargerRequest, disableChargerRequest, executeRPCCommand };
}
