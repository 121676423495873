const config = {
  keyName: "userId",
  post: "/userChargerLog/deleteSessionsByUsers",
  message: "message",
  fields: [
    {
      title: "Seleccionar usuarios",
      key: "userIDs",
      textKey: "userIDs",
      sorter: true,
      filter: true,
      type: "select",
      features: {
        filtrable: true,
      },
      mode: "multiple",
      col: 24,
      configOptions: {
        url: "/options/allUsers",
        map: (item) => ({ [item.value]: item.text }),
      },
      rules: [{ required: true, message: "Este campo es obligatorio." }],
    },
  ],
};

export default config;
