import moment from "moment";
import instance from "../services/instance";
import { dateTimeFormat, TIME_FORMAT } from "../config/constants";

export const nextNumber =
  (next = 1) =>
  () =>
    next++;

const nextRouterIndex = nextNumber();
export const createRoute = (url, component, when = null, exact = false) => ({
  index: true,
  path: url,
  Component: component,
  caseSensitive: exact,
});

const nextMenuIndex = nextNumber();
export const createMenu = (url, title, icon, when) => ({
  index: nextMenuIndex(),
  title,
  path: url,
  icon,
  when,
});
export const createComponent = (component, when) => ({
  index: nextMenuIndex(),
  component,
  when,
});

export const createGroupMenu = (icon, name, subMenus) => ({
  index: nextMenuIndex(),
  icon,
  name,
  subMenus,
});

export const sortString = (key) => (a, b) =>
  a[key] ? a[key].toString().localeCompare(b[key]) : true;
export const sortNumber = (key) => (a, b) => a[key] - b[key];
export const sortBool = (key) => (a, b) => b[key] - a[key];

export const groupBy = (array, prop) =>
  array.reduce((groups, item) => {
    const val = item[prop];
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});

export const getMaxNumber = (array, prop) => {
  let n = array.length;
  let maximum = array[n - 1][prop];
  let el = array[n - 1];
  while (n--) {
    if (array[n][prop] > maximum) {
      maximum = array[n][prop];
      el = array[n];
    }
  }
  return el;
};

export const dateToString = (utcDate) => {
  if (utcDate == null) {
    return null;
  }
  const stillUtc = moment.utc(utcDate).toDate();
  const local = moment(stillUtc).local();
  return local.format(dateTimeFormat);
  // return utcDate  !== null ? moment(utcDate).format(dateTimeFormat) : "--";
  // return utcDate;
};

export const timeToMoment = (time) => {
  if (time == null) {
    return null;
  }
  return time !== null ? moment(time, TIME_FORMAT) : null;
};

export const toLocalTime = (utcTime) => {
  if (utcTime == null) {
    return null;
  }
  const utcString = moment(utcTime).format("YYYY-MM-DD HH:mm:ss UTC");
  const local = moment(utcString, "YYYY-MM-DD HH:mm:ss UTC").format(
    dateTimeFormat
  );
  return local;
};

export async function downloadImage(imageSrc, fileName) {
  const image = await fetch(imageSrc);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement("a");
  link.href = imageURL;
  link.download = fileName || "OASIS-Image";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const validatePassword = (_password) => {
  if ([null, undefined, ""].indexOf(_password) >= 0) {
    return "";
  }
  const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g;
  const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
  const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
  const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
  const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
  const eightCharsOrMore = /.{8,}/g; // eight characters or more

  const passwordTracker = (password) => ({
    uppercase: password ? password.match(atLeastOneUppercase) : "",
    lowercase: password ? password.match(atLeastOneLowercase) : "",
    number: password ? password.match(atLeastOneNumeric) : "",
    specialChar: password ? password.match(atLeastOneSpecialChar) : "",
    eightCharsOrGreater: password ? password.match(eightCharsOrMore) : "",
  });

  const passwordStrength = (password) =>
    Object.values(passwordTracker(password)).filter((value) => value).length;

  // ${
  //   passwordStrength(_password) < 5
  //     ? "La Contraseña debe de contener al menos "
  //     : ""
  // }

  return `${
    !passwordTracker(_password).uppercase
      ? "Debe de tener letras mayúsculas. "
      : ""
  }
${!passwordTracker(_password).lowercase ? "Minúsculas. " : ""}
${
  !passwordTracker(_password).specialChar
    ? "Almenos un caracter especial. "
    : ""
}

${!passwordTracker(_password).number ? "Almenos un número. " : ""}
${
  !passwordTracker(_password).eightCharsOrGreater
    ? "La contraseña debe ser mayor a 8 caracteres. "
    : ""
}`;
};

export const validateLength = (value, length) => {
  if (`${value}`.length < length) {
    return Promise.reject(
      new Error(`¡Este campo debe de contener al menos ${length} caracteres!`)
    );
  }
  return Promise.resolve();
};

export const downloadFilePromise = ({ url, method = "GET", params = {} }) =>
  instance({
    url,
    params,
    method,
    responseType: "blob",
  }).then((response) => {
    downloadFile(response.data);
  });

export const downloadFile = (blobData) => {
  const url = window.URL.createObjectURL(new Blob([blobData]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `file_${new Date().valueOf()}.xlsx`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
