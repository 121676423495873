import React from "react";
import { Layout, Row, Col, Space } from "antd";
import { HomeOutlined, HomeFilled } from "@ant-design/icons";
import { NotificationCenter, HeaderUser } from ".";
import { NOTIFICATION_GROUP } from "../../config/constants";

const Header = ({ pathname, isCollapsed, showDrawer, drawerVisible }) => (
  <Layout.Header className="custom-header">
    <Row type="flex" align="middle" justify="end">
      <Col className="custom-align-right">
        <Space>
          {Object.keys(NOTIFICATION_GROUP).map((key) => (
            <NotificationCenter group={NOTIFICATION_GROUP[key]} />
          ))}
          <HeaderUser />
        </Space>
      </Col>
    </Row>
  </Layout.Header>
);

Header.defaultProps = {
  isCollapsed: false,
  drawerVisible: false,
};

export default Header;

//
