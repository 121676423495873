import React, { useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Form as FormAnt } from "antd";

import { Form as FormCrud, useCrudForm } from "../../components/Crud";
import conf from "../../config/crud/reportFilters";
import { dateFormat } from "../../config/constants";

const ReportFilter = ({ onFilter, values, showFields = [] }) => {
  const propsForm = useCrudForm({ conf, key: null });
  const [form] = FormAnt.useForm();

  useEffect(() => {
    if (values) {
      form.setFieldsValue(values);
    }
  }, [values]);
  useEffect(() => {
    propsForm.fields.forEach((field) => {
      const hide = showFields.indexOf(field.key) === -1;
      field.hide = hide;
      if (hide && field.rules.length > 0) {
        delete field.rules;
      } else if (!hide && field.rules.length > 0) {
        field.rules = [
          { required: true, message: "Este campo es obligatorio." },
        ];
      }
    });
  }, [propsForm.fields]);

  const onSubmit = (values) => {
    const filters = { ...values };
    if (values.range.length === 2) {
      filters.fromDate = `${moment(values.range[0]).format(
        dateFormat
      )} 00:00:00`;
      filters.untilDate = `${moment(values.range[1]).format(
        dateFormat
      )} 23:59:59`;
    }
    onFilter(filters);
  };

  const resetHandler = () => {
    form.resetFields();
  };

  return (
    <FormCrud
      key={Date.now().valueOf()}
      hideTitle
      layout="vertical"
      verticalLabel
      subTitle={"Filtros"}
      rowKey={conf.keyName}
      submitButtonText="Consultar"
      buttonsAlign={"center"}
      {...propsForm}
      refForm={form}
      onSubmit={onSubmit}
      onSubmited={() => {}}
      cancelText={"Limpiar Filtros"}
      onCancel={resetHandler}
    />
  );
};

ReportFilter.propTypes = {
  onFilter: PropTypes.func,
  values: PropTypes.object,
  showFields: PropTypes.arrayOf(PropTypes.string),
};

export default ReportFilter;
