import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import {
  Badge,
  Avatar,
  Dropdown,
  Button,
  Typography,
  Empty,
  Divider,
  Space,
} from "antd";
import {
  NotificationFilled,
  AlertFilled,
  ThunderboltTwoTone,
  ThunderboltOutlined,
} from "@ant-design/icons";

import { AiOutlineUserAdd } from "react-icons/ai";
import { FaMicrochip, FaHeadset } from "react-icons/fa";
import {
  TbGasStationOff,
  TbGasStation,
  TbBatteryCharging2,
  TbBatteryCharging,
} from "react-icons/tb";
import {
  MdManageAccounts,
  MdOutlineCreditScore,
  MdBatteryCharging20,
} from "react-icons/md";
import { LiaSmsSolid } from "react-icons/lia";

import { toLocalTime } from "../../utils/general";
import { NOTIFICATION_GROUP, NOTIFICATION_TYPE } from "../../config/constants";

const iconByType = {
  [NOTIFICATION_TYPE.CHARGER_ON]: <TbBatteryCharging2 size={25} />,
  [NOTIFICATION_TYPE.CHARGER_OFF]: <TbBatteryCharging size={25} />,
  [NOTIFICATION_TYPE.RPC_DESCONNECTED]: <FaMicrochip size={25} />,
  [NOTIFICATION_TYPE.NEW_PQRS]: <FaHeadset size={25} />,
  [NOTIFICATION_TYPE.CHARGER_DESCONNECTED]: <MdBatteryCharging20 size={25} />,
  [NOTIFICATION_TYPE.STATION_DISABLED]: <TbGasStationOff size={25} />,
  [NOTIFICATION_TYPE.STATION_ENABLED]: <TbGasStation size={25} />,
  [NOTIFICATION_TYPE.NEW_USER]: <AiOutlineUserAdd size={25} />,
  [NOTIFICATION_TYPE.SMS_PROVIDER_CHANGED]: <LiaSmsSolid size={25} />,
  [NOTIFICATION_TYPE.PAYMENT_PLATFORM_CHANGED]: (
    <MdOutlineCreditScore size={25} />
  ),
};

const NotificationButton = {
  [NOTIFICATION_GROUP.CHARGING]: <MdManageAccounts />,
  [NOTIFICATION_GROUP.PQRS]: <FaHeadset />,
  [NOTIFICATION_GROUP.MANAGEMENT]: <NotificationFilled />,
};

const { Text } = Typography;

const emptyItem = [
  {
    label: <Empty description="No hay notificaciones" />,
    key: "-1",
  },
];

const menuStyle = {
  overflow: "auto",
  maxHeight: "500px",
  boxShadow: "none",
};

const contentStyle = {
  boxShadow:
    "0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)",
};

const Notifications = ({
  allNotifications,
  remove,
  group,
  removeByGroupType,
  showed,
  setAsShow,
}) => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (showed && showed[group] == false) {
      setCount(notifications.filter((x) => x.status === "NEW").length);
    }
  }, [notifications, showed]);

  useEffect(() => {
    if (allNotifications) {
      setNotifications(allNotifications.filter((x) => x.group === group));
    }
  }, [allNotifications]);
  return (
    <Dropdown
      trigger={["click"]}
      menu={{
        items:
          notifications.length === 0
            ? emptyItem
            : notifications.map((x) => ({
                key: x.key,
                icon: iconByType[x.type],
                label: (
                  <div
                    onClick={() => remove(x.key, () => navigate(x.redirect))}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {x.title}
                    <Text type="secondary" italic style={{ fontSize: ".7rem" }}>
                      {toLocalTime(x.date)}
                    </Text>
                  </div>
                ),
              })),
      }}
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          {React.cloneElement(menu, {
            style: menuStyle,
          })}
          {notifications.length > 0 && (
            <>
              <Divider
                style={{
                  margin: 0,
                }}
              />
              <div
                style={{
                  padding: 8,
                  width: "100%",
                }}
              >
                <Button
                  block
                  onClick={() => removeByGroupType(group, () => {})}
                >
                  Limpiar notificaiones
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    >
      <a
        onClick={(e) => {
          e.preventDefault();
          setAsShow(group);
          setCount(0);
        }}
      >
        <Badge count={count}>
          <Avatar
            shape="circle"
            size="large"
            icon={NotificationButton[group]}
          />
        </Badge>
      </a>
    </Dropdown>
  );
};

Notifications.propTypes = {
  group: PropTypes.string,
};

const mapState = (state) => ({
  allNotifications: state.notifications.list,
  showed: state.notifications.showed,
});
const mapDispatch = (dispatch) => ({
  remove: (key, callback) => dispatch.notifications.remove({ key, callback }),
  setAsShow: (group) => dispatch.notifications.setAsShow({ group }),
  removeByGroupType: (group, callback) =>
    dispatch.notifications.removeByGroup({ group, callback }),
});

export default connect(mapState, mapDispatch)(Notifications);
