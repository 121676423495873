import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form as FormAnt } from "antd";
import { Form as FormCrud, useCrudForm } from "../../components/Crud";
import conf from "../../config/crud/removeTestSessions";
import { showSuccess } from "../../utils/message";

const RemoveTestSessions = ({ onDeleted = () => {} }) => {
  const [visible, setVisible] = useState(false);
  const [form] = FormAnt.useForm();

  const propsForm = useCrudForm({ conf, key: null });

  const events = {
    open: () => setVisible(true),
    close: () => setVisible(false),
    submit: () => {
      form
        .validateFields()
        .then((values) => {
          propsForm.onSubmit(values, () => {
            showSuccess("Información eliminada con éxito");
            events.close();
            onDeleted();
          });
        })
        .catch(() => {});
    },
  };

  return (
    <>
      <Button type="primary" danger onClick={events.open}>
        Eliminar Sesiones de Prueba
      </Button>
      <Modal
        centered
        maskClosable={false}
        open={visible}
        width={1000}
        onOk={events.submit}
        okText={`Eliminar sesiones `}
        onCancel={events.close}
      >
        <FormCrud
          refForm={form}
          title={`Eliminar sesiones de prueba`}
          rowKey={conf.keyName}
          layout="vertical"
          hideButtons={true}
          {...propsForm}
        />
      </Modal>
    </>
  );
};

RemoveTestSessions.propTypes = {
  onDeleted: PropTypes.func,
};

export default RemoveTestSessions;
