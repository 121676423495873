import { createMenu, createComponent } from "../utils/general";
import { LOGGED, GUEST } from "./constants";
import { HeaderUser } from "../components/Layout";
import { ROUTES } from "./constants";
import { groupBy, createGroupMenu } from "../utils/general";

const menus = {
  primary: [
    createMenu(ROUTES.home, "Home", "FaHome"),
    createGroupMenu("FaUserFriends", "Administración", [
      createMenu(ROUTES.role, "Roles", "FaUserCog"),
      createMenu(ROUTES.users, "Usuarios", "FaUserAlt"),
      createMenu(ROUTES.sponsor, "Patrocinadores", "FaUserTie"),
      createMenu(ROUTES.notification, "Notificaciones", "FaVolumeUp"),
      createMenu(ROUTES.question, "Preguntas", "FaQuestion"),
    ]),
    createGroupMenu("FaRegNewspaper", "Estaciones", [
      createMenu(ROUTES.chargerConnectorType, "Tipos de conectores", "FaPlug"),
      createMenu(ROUTES.station, "Estaciones", "FaChargingStation"),
      createMenu(
        ROUTES.outerStation,
        "Estaciones Externas",
        "FaChargingStation"
      ),
      createMenu(ROUTES.rpc, "RPC's", "FaMicrochip"),
      createMenu(
        ROUTES.publicity,
        "Campañas de Publicidad",
        "FaCommentsDollar"
      ),
    ]),
    createGroupMenu("FaRegNewspaper", "Reportes", [
      createMenu(ROUTES.businessPartner, "Aliados Comerciales", "FaUserSecret"),
      createMenu(ROUTES.sponsorReport, "Patrocinadores", "FaUserTie"),
      createMenu(ROUTES.questionReport, "Preguntas", "FaRegQuestionCircle"),
      createMenu(ROUTES.customerReport, "Clientes", "FaUserTie"),
      createMenu(ROUTES.allUsersReport, "Usuarios", "FaUser"),
    ]),
    createGroupMenu("FaRegFolderOpen", "Operaciones", [
      createMenu(ROUTES.userChargerLog, "Conexiones/Usuarios", "FaBolt"),
      createMenu(ROUTES.heatMap, "Mapa de Calor", "FaGlobeAmericas"),
      createMenu(ROUTES.pqrs, "PQRS's", "FaHeadset"),
      createMenu(ROUTES.carbonSeal, "Certificados de Carbono", "FaBlackTie"),
    ]),
    createGroupMenu("FaCogs", "Configuraciones", [
      createMenu(ROUTES.settings, "Configuraciones", "FaCogs"),
      createMenu(ROUTES.task, "Tareas", "FaTasks"),
      createMenu(ROUTES.paymentPlatform, "Métodos de Pago", "FaMoneyCheck"),
      createMenu(ROUTES.smsProvider, "Proveedores de SMS", "FaSms"),
      createMenu(ROUTES.emailTemplate, "Mail Template", "FaMailBulk"),
    ]),
  ],
  header: [createComponent(() => HeaderUser, LOGGED)],
};

// export default menus;

// const menus = () => {
//   const buildMenuObject = filter => {
//     if (menus.length === 0) {
//       return [];
//     }

//     const items = groupBy(menus, 'type')[filter];
//     if (items == null) {
//       return [];
//     }

//     return [
//       ...items.map(menu =>
//         menu.route == null
//           ? createGroupMenu(
//               menu.icon,
//               `menu.${menu.name}`,
//               menu.submenus.map(x =>
//                 createMenu(x.route, `menu.${x.name}`, x.icon, LOGGED),
//               ),
//             )
//           : createMenu(menu.route, `menu.${menu.name}`, menu.icon, LOGGED),
//       ),
//     ];
//   };
//   return {
//     primary: buildMenuObject('primary'),
//     header: [...buildMenuObject('header')],
//   };
// };

export default menus;
