import moment from "moment";
import { dateTimeFormat } from "../config/constants";

/***
 * NotificationObject =>
 * key: unique
 * type: NOTIFICATION_TYPE
 * group: PQRS | CHARGING | MANAGEMENT
 * title: string
 * status: PENDING | OPENED | NEW
 * onClick: () => {}, removeFromList
 * date: string
 */
const notifications = {
  state: {
    list: [],
    showed: {
      PQRS: false,
      CHARGING: false,
      MANAGEMENT: false,
    },
  },
  reducers: {
    ADD: (state, payload) => ({
      ...state,
      list: [
        {
          status: "NEW",
          ...payload,
        },
        ...state.list,
      ],
      showed: {
        ...state.showed,
        [payload.group]: false,
      },
    }),
    SET_AT_VIEWED: (state, payload) => ({
      ...state,
      list: state.list.map((e) => {
        if (e.group === payload) {
          e.status = "PENDING";
        }
        return e;
      }),
      showed: {
        ...state.showed,
        [payload]: true,
      },
    }),
    REMOVE: (state, payload) => ({
      ...state,
      list: state.list.filter((x) => x.key !== payload),
    }),
    REMOVE_BY_GROUP: (state, payload) => ({
      ...state,
      list: state.list.filter((x) => x.group !== payload),
    }),
  },
  effects: {
    /**
     * Agrega la notificación al conponente
     * @param {notification: {key: string, type: NOTIFICATION_TYPE, title: string, onClick: () => {}, date: string }, callback: () => {}} param0
     */
    show({ notification, callback = () => {} }) {
      this.ADD({
        ...notification,
        date: moment().format(dateTimeFormat),
      });
      callback();
    },
    /**
     * Elimina una notificación por id
     * @param {key: string, callback: () => {}} param0 parametros
     */
    remove({ key, callback = () => {} }) {
      this.REMOVE(key);
      callback();
    },
    /**
     * Elimina todas las notificaiones de un mismo tipo de grupo
     * @param {group: string, callback: () => {}} param0 parametros
     */
    removeByGroup({ group, callback = () => {} }) {
      this.REMOVE_BY_GROUP(group);
      callback();
    },
    setAsShow({ group }) {
      this.SET_AT_VIEWED(group);
    },
  },
};

export default notifications;
