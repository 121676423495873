import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography, Divider } from "antd";

const { Title } = Typography;

const DataSummary = ({ data, property }) => {
  const [totalTime, setTotalTime] = useState(0);
  useEffect(() => {
    if (data) {
      setTotalTime(data.reduce((a, b) => a + b[property], 0));
    }
  }, [data]);
  return (
    <>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Title level={4}>Total de sesiones: {data.length} sesiones</Title>
        </Col>
        <Col span={12}>
          <Title level={4}>Tiempo total de conexiones: {totalTime} horas</Title>
        </Col>
      </Row>
    </>
  );
};

DataSummary.propTypes = {
  data: PropTypes.object,
  properties: PropTypes.array,
};

export default DataSummary;
