import React, { createContext } from "react";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import { useChargerSocket } from "../components/Charger/hooks";
import {
  NOTIFICATION_GROUP,
  NOTIFICATION_TYPE,
  ROUTES,
} from "../config/constants";

export const SocketContext = createContext({});

export const SocketProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();

  const openNotification = ({
    title = `Nueva notificación`,
    message,
    type = "info",
  }) => {
    api[type]({
      message: title,
      description: message,
      placement: "topRight",
    });
  };

  const socketHandler = (data) => {
    const { transactionId } = data;
    switch (data.status) {
      case "ON":
        addNotification(
          NOTIFICATION_GROUP.CHARGING,
          NOTIFICATION_TYPE.CHARGER_ON,
          `Cargador encendido, número de transacción: ${transactionId}`,
          () =>
            openNotification({
              title: "Cargador Encendido",
              message: `Se ha iniciado una carga con el número de transacción ${transactionId}`,
            }),
          `${ROUTES.userChargerLog}/${transactionId}`
        );
        break;
      case "OFF_ADMIN":
      case "CHARGING_ENDED_BY_OCLOUD":
      case "OFF":
        addNotification(
          NOTIFICATION_GROUP.CHARGING,
          NOTIFICATION_TYPE.CHARGER_OFF,
          `Cargador apagado, número de transacción: ${transactionId}`,
          () =>
            openNotification({
              title: "Cargador Apagado",
              message: `Se ha desconectado un cargador; con el número de transacción ${transactionId}`,
            }),
          `${ROUTES.userChargerLog}/${transactionId}`
        );
        break;
      case "RPC_OFF":
        addNotification(
          NOTIFICATION_GROUP.CHARGING,
          NOTIFICATION_TYPE.RPC_DESCONNECTED,
          `RPC desconectada`,
          () =>
            openNotification({
              title: "RPC desconectada",
              message: `Se ha desconectado una o más RPC`,
            }),
          `${ROUTES.rpc}`
        );
        break;
      case NOTIFICATION_TYPE.NEW_PQRS:
        addNotification(
          NOTIFICATION_GROUP.PQRS,
          NOTIFICATION_TYPE.NEW_PQRS,
          `Nuevo PQRS Generado`,
          () =>
            openNotification({
              title: "Nuevo PQRS Generado",
              message: `Se ha generado un PQRS con el número de radicado: ${data.filingNumber}`,
            }),
          `${ROUTES.pqrs}/${data.filingNumber}`
        );
        break;
      case NOTIFICATION_TYPE.PQRS_ANSWERED:
        addNotification(
          NOTIFICATION_GROUP.PQRS,
          NOTIFICATION_TYPE.PQRS_ANSWERED,
          `Un PQRS fué respondido`,
          () =>
            openNotification({
              title: "Un PQRS fué respondido",
              message: `Se ha dado respuesta al PQRS con el número de radicado: ${data.filingNumber}`,
            }),
          `${ROUTES.pqrs}/${data.filingNumber}`
        );
        break;
      case NOTIFICATION_TYPE.RPC_DESCONNECTED:
        addNotification(
          NOTIFICATION_GROUP.CHARGING,
          NOTIFICATION_TYPE.RPC_DESCONNECTED,
          `En la estación ${data.stationName}, Se ha desconectado la RPC ${data.rpcName}`,
          () =>
            openNotification({
              title: "RPC Desconectada",
              message: `En la estación ${data.stationName}, Se ha desconectado la RPC ${data.rpcName}`,
            }),
          `${ROUTES.rpc}`
        );
        break;
      case NOTIFICATION_TYPE.STATION_DISABLED:
        addNotification(
          NOTIFICATION_GROUP.CHARGING,
          NOTIFICATION_TYPE.STATION_DISABLED,
          `La estación ${data.stationName}, se encuentra fuera de servicio`,
          () =>
            openNotification({
              title: "Estación en mantenimiento",
              message: `La estación ${data.stationName}, se encuentra fuera de servicio`,
            }),
          `${ROUTES.pqrs}/${data.filingNumber}`
        );
        break;
      case NOTIFICATION_TYPE.STATION_ENABLED:
        addNotification(
          NOTIFICATION_GROUP.CHARGING,
          NOTIFICATION_TYPE.STATION_ENABLED,
          `La estación ${data.stationName}, se encuentra nuevamente operando`,
          () =>
            openNotification({
              title: "Estación nuevamente funcionando",
              message: `La estación ${data.stationName}, nuevamente operando`,
            }),
          `${ROUTES.pqrs}/${data.filingNumber}`
        );
        break;
      case NOTIFICATION_TYPE.NEW_USER:
        addNotification(
          NOTIFICATION_GROUP.MANAGEMENT,
          NOTIFICATION_TYPE.NEW_USER,
          `Se ha creado el usuario ${data.username}`,
          () =>
            openNotification({
              title: "Nuevo usuario",
              message: `Se ha creado el usuario ${data.username}`,
            }),
          `${ROUTES.users}`
        );
        break;
      case NOTIFICATION_TYPE.PAYMENT_PLATFORM_CHANGED:
        addNotification(
          NOTIFICATION_GROUP.MANAGEMENT,
          NOTIFICATION_TYPE.PAYMENT_PLATFORM_CHANGED,
          `Se ha cambiado el método de pago`,
          () =>
            openNotification({
              title: "Plataforma de pagos cambiada",
              message: `Se ha cambiado el método de pago`,
            }),
          `${ROUTES.paymentPlatform}`
        );
        break;
      case NOTIFICATION_TYPE.SMS_PROVIDER_CHANGED:
        addNotification(
          NOTIFICATION_GROUP.MANAGEMENT,
          NOTIFICATION_TYPE.SMS_PROVIDER_CHANGED,
          `Se ha cambiado el proveedor de mensajes de texto`,
          () =>
            openNotification({
              title: "proveedor de SMS cambiado",
              message: `Se ha cambiado el proveedor de mensajes de texto`,
            }),
          `${ROUTES.users}`
        );
        break;
    }
  };

  const addNotification = (group, type, title, callback, redirect) => {
    const key = new Date().valueOf();
    dispatch.notifications.show({
      notification: {
        key,
        group,
        type,
        title,
        redirect,
      },
      callback,
    });
  };

  const {
    activeChargerRequest,
    disableChargerRequest,
    turnOffChargingByChargerDisabed,
  } = useChargerSocket({
    observer: socketHandler,
  });

  return (
    <SocketContext.Provider
      value={{
        //Methods
        activeChargerRequest,
        disableChargerRequest,
        turnOffChargingByChargerDisabed,
        openNotification,
      }}
    >
      <>
        {contextHolder}
        {children}
      </>
    </SocketContext.Provider>
  );
};
