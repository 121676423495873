import axios from "axios";

import {
  API_KEY,
  API_URL,
  STATUS_CODE,
  TYPE_MESSAGE,
} from "../config/constants";
import { showError, showWarning } from "../utils/message";

class AxiosCreate {
  static _instance = null;

  constructor() {
    const headers = {
      "x-api-key": API_KEY,
    };
    this._instance = axios.create({
      baseURL: API_URL,
      headers,
    });

    this._instance.interceptors.response.use(
      (response) => response,
      (error) => {
        switch (error.response.status) {
          case 401:
            if (error.request.responseURL?.indexOf("signIn") === -1) {
              document.location.href = "/login";
            }
            return Promise.resolve(error.response);
          case 500:
            if (error.response?.data?.statusCode === STATUS_CODE.FAILURE) {
              return Promise.reject({
                type: TYPE_MESSAGE.Error,
                message: error.response?.data?.message,
              });
            }
            return Promise.reject(error);
          case 501:
          case 400:
            let message = null;
            if (error.response?.data?.statusCode === STATUS_CODE.FAILURE) {
              message = error.response?.data?.message;
              showError(message);
            }
            if (
              error.response?.data?.statusCode === STATUS_CODE.VALIDATION_ERROR
            ) {
              message = error.response?.data?.message;
            }
            return Promise.reject(message || error);
          default:
            return Promise.reject(error);
        }
      }
    );
  }

  get instance() {
    return this._instance;
  }
}

const InstAx = new AxiosCreate();

InstAx.instance.setToken = (token) => {
  InstAx.instance.defaults.headers.Authorization = `Bearer ${token}`;
};

InstAx.instance.removeToken = () => {
  InstAx.instance.defaults.headers.Authorization = null;
};

export default InstAx.instance;
