import React, { Fragment } from "react";
import { Table, Button, Row, Col, Tooltip, Popconfirm } from "antd";
import { Title } from "../Shared";

const List = ({
  title,
  columns,
  dataSource,
  addButtons,
  addActions,
  subTitle,
  actionsStyles,
  ...rest
}) => {
  return (
    <div className="form-container-generic">
      <>
        <Row
          type="flex"
          align="middle"
          justify="space-between"
          gutter={[16, 16]}
        >
          <Col>
            <Title text={title} />
            <h3>{subTitle}</h3>
          </Col>
          <Col>
            {addButtons &&
              addButtons.map(
                (buttonProps, index) =>
                  buttonProps.hidden || (
                    <Fragment key={index}>
                      <Button size="large" type="primary" {...buttonProps}>
                        {buttonProps.text}
                      </Button>{" "}
                    </Fragment>
                  )
              )}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Table
            scroll={{ x: true }}
            columns={[
              ...columns.map((column) => {
                if (column.filters) {
                  column.filters = column.filters.map((filter) => ({
                    ...filter,
                    value: filter.value,
                    text: filter.text,
                  }));
                }
                return {
                  ...column,
                  title: column.title,
                };
              }),
              addActions
                ? {
                    title: "Acciones",
                    dataIndex: "buttons",
                    key: `${Date.now().valueOf}`,
                    align: "right",
                    width: 50 * addActions?.length,
                    ...(actionsStyles || {}),
                    render: (value, record) => ({
                      props: {
                        ...record.props,
                      },
                      children: record.hideButtons
                        ? ""
                        : addActions?.map((action) =>
                            action.hide && action.hide(record) ? (
                              ""
                            ) : (
                              <Tooltip key={action.text} title={action.text}>
                                {action.confirm ? (
                                  <Popconfirm
                                    disabled={action.disabled}
                                    title={action.confirm}
                                    placement="bottomRight"
                                    onConfirm={() => action.onClick(record)}
                                  >
                                    <Button
                                      type={action.type || "ghost"}
                                      shape="circle"
                                      icon={action.icon}
                                      disabled={action.disabled}
                                    />
                                  </Popconfirm>
                                ) : (
                                  <Button
                                    type={action.type || "ghost"}
                                    key={action.text}
                                    shape="circle"
                                    icon={action.icon}
                                    onClick={() => action.onClick(record)}
                                    disabled={action.disabled}
                                    className={action.className || ""}
                                  />
                                )}{" "}
                              </Tooltip>
                            )
                          ),
                    }),
                  }
                : {},
            ]}
            dataSource={dataSource}
            className="custom-table"
            {...rest}
          />
        </Row>
      </>
    </div>
  );
};

export default List;
