import React, { lazy, Suspense } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import { Spin } from "antd";

import { AuthRoute } from "../components/Auth";
import NotFound404 from "../pages/404";
import { ROUTES } from "./constants";
import Home from "../pages/Home";
import Layout from "../pages/Layout";
import Auth from "../pages/Auth";

/**Shared */
const AsyncAboutUs = lazy(() => import("../pages/About"));

/**Security */
const AsyncSignIn = lazy(() => import("../pages/Auth/singIn"));
const AsyncSignUp = lazy(() => import("../pages/Auth/singUp"));
const AsyncForgotPassword = lazy(() => import("../pages/Auth/ForgotPassword"));
const AsyncChangePassword = lazy(() => import("../pages/Auth/RecoverPassword"));

/**Use Case RPC */
const AsyncRpcList = lazy(() => import("../pages/Rpc/List"));
const AsyncRpcForm = lazy(() => import("../pages/Rpc/Form"));

/**Use Case Charger */
const AsyncChargerList = lazy(() => import("../pages/Charger/List"));
const AsyncChargerForm = lazy(() => import("../pages/Charger/Form"));

const AsyncLogChargerList = lazy(() => import("../pages/UserChargerLog/List"));
const AsyncLogChargerDetails = lazy(() =>
  import("../pages/UserChargerLog/Details")
);

const AsyncStationList = lazy(() => import("../pages/Station/List"));
const AsyncStationForm = lazy(() => import("../pages/Station/Form"));

const AsyncParameters = lazy(() => import("../pages/ConfigParameters"));
const AsyncParametersForm = lazy(() =>
  import("../pages/ConfigParameters/Form")
);

const AsyncPqrsList = lazy(() => import("../pages/Pqrs/List"));
const AsyncPqrsForm = lazy(() => import("../pages/Pqrs/Form"));

const AsyncSponsorList = lazy(() => import("../pages/Sponsor"));
const AsyncSponsorForm = lazy(() => import("../pages/Sponsor/Form"));
const AsyncSponsorReport = lazy(() => import("../pages/Sponsor/Report"));

const AsyncFreeChargingForm = lazy(() =>
  import("../pages/FreeCharging/RegistrationForm")
);
const AsyncHeatMap = lazy(() =>
  import("../pages/FreeCharging/UserAddressVisualization")
);

const AsyncUsers = lazy(() => import("../pages/User"));
const AsyncUsersForm = lazy(() => import("../pages/User/Form"));
const AsyncCustomerReport = lazy(() => import("../pages/User/Report"));
const AsyncUsersReport = lazy(() => import("../pages/User/AllUsersReport"));

const AsyncRole = lazy(() => import("../pages/Role"));
const AsyncRoleForm = lazy(() => import("../pages/Role/Form"));

const AsyncQuestion = lazy(() => import("../pages/Question"));
const AsyncQuestionForm = lazy(() => import("../pages/Question/Form"));
const AsyncQestionReport = lazy(() => import("../pages/Question/Report"));

const AsyncNotifications = lazy(() => import("../pages/Notifications"));
const AsyncNotificationForm = lazy(() => import("../pages/Notifications/Form"));

const AsyncPublicity = lazy(() => import("../pages/PublicityCampaing"));
const AsyncPublicityForm = lazy(() =>
  import("../pages/PublicityCampaing/Form")
);

const AsyncBusinessPartner = lazy(() => import("../pages/BusinnesPartner"));

const AsyncPaymentPlatform = lazy(() =>
  import("../pages/ConfigParameters/PaymentPlatform")
);

const AsyncSmsProvider = lazy(() =>
  import("../pages/ConfigParameters/SmsProviderForm")
);

const AsyncCarbonSeal = lazy(() => import("../pages/CarbonSeal"));
const AsyncCarbonSealForm = lazy(() => import("../pages/CarbonSeal/Form"));

const AsyncTask = lazy(() => import("../pages/Task"));
const AsyncTaskForm = lazy(() => import("../pages/Task/Form"));

const AsyncMailTemplate = lazy(() => import("../pages/EmailTemplate"));
const AsyncMailTemplateForm = lazy(() => import("../pages/EmailTemplate/Form"));

const AsyncOuterStationList = lazy(() => import("../pages/OuterStation/List"));
const AsyncOuterStationForm = lazy(() => import("../pages/OuterStation/Form"));

const AsyncChargerConnectorTypeList = lazy(() =>
  import("../pages/ChargerConnectorType")
);
const AsyncChargerConnectorTypeForm = lazy(() =>
  import("../pages/ChargerConnectorType/Form")
);

const LazyComponent = ({ children }) => (
  <Suspense
    fallback={<Spin size="large" className="custom-layout-spin"></Spin>}
  >
    {children}
  </Suspense>
);

const PrivateRoute = ({ children, permission }) => (
  <AuthRoute permission={permission}>
    <LazyComponent>{children}</LazyComponent>
  </AuthRoute>
);

const Router = () => (
  <Routes>
    <Route path={ROUTES.home} element={<Layout />}>
      <Route
        index
        element={
          <PrivateRoute permission={ROUTES.home}>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.businessPartner}
        element={
          <PrivateRoute permission={ROUTES.businessPartner}>
            <AsyncBusinessPartner />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.paymentPlatform}
        element={
          <PrivateRoute permission={ROUTES.paymentPlatform}>
            <AsyncPaymentPlatform />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.smsProvider}
        element={
          <PrivateRoute permission={ROUTES.smsProvider}>
            <AsyncSmsProvider />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.questionReport}
        element={
          <LazyComponent permission={ROUTES.questionReport}>
            <AsyncQestionReport />
          </LazyComponent>
        }
      />
      <Route
        path={ROUTES.customerReport}
        element={
          <LazyComponent permission={ROUTES.customerReport}>
            <AsyncCustomerReport />
          </LazyComponent>
        }
      />
      <Route
        path={ROUTES.sponsorReport}
        element={
          <PrivateRoute permission={ROUTES.sponsorReport}>
            <AsyncSponsorReport />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.allUsersReport}
        element={
          <PrivateRoute permission={ROUTES.allUsersReport}>
            <AsyncUsersReport />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.heatMap}
        element={
          <LazyComponent>
            <AsyncHeatMap />
          </LazyComponent>
        }
      />
      <Route
        path={ROUTES.role}
        element={
          <PrivateRoute permission={ROUTES.role}>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={<AsyncRole />} />
        <Route path=":id" element={<AsyncRoleForm />} />
        <Route path="new" element={<AsyncRoleForm />} />
      </Route>
      <Route
        path={ROUTES.emailTemplate}
        element={
          <PrivateRoute permission={ROUTES.emailTemplate}>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={<AsyncMailTemplate />} />
        <Route path=":id" element={<AsyncMailTemplateForm />} />
        <Route path="new" element={<AsyncMailTemplateForm />} />
      </Route>
      <Route
        path={ROUTES.chargerConnectorType}
        element={
          <PrivateRoute permission={ROUTES.chargerConnectorType}>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={<AsyncChargerConnectorTypeList />} />
        <Route path=":id" element={<AsyncChargerConnectorTypeForm />} />
        <Route path="new" element={<AsyncChargerConnectorTypeForm />} />
      </Route>
      <Route
        path={ROUTES.task}
        element={
          <PrivateRoute permission={ROUTES.task}>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={<AsyncTask />} />
        <Route path=":id" element={<AsyncTaskForm />} />
        <Route path="new" element={<AsyncTaskForm />} />
      </Route>
      <Route
        path={ROUTES.carbonSeal}
        element={
          <PrivateRoute permission={ROUTES.carbonSeal}>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={<AsyncCarbonSeal />} />
        <Route path=":id" element={<AsyncCarbonSealForm />} />
        <Route path="new" element={<AsyncCarbonSealForm />} />
      </Route>
      <Route
        path={ROUTES.publicity}
        element={
          <PrivateRoute permission={ROUTES.publicity}>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={<AsyncPublicity />} />
        <Route path=":id" element={<AsyncPublicityForm />} />
        <Route path="new" element={<AsyncPublicityForm />} />
      </Route>
      <Route
        path={ROUTES.notification}
        element={
          <PrivateRoute permission={ROUTES.notification}>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={<AsyncNotifications />} />
        <Route path=":id" element={<AsyncNotificationForm />} />
        <Route path="new" element={<AsyncNotificationForm />} />
      </Route>
      <Route
        path={ROUTES.question}
        element={
          <PrivateRoute permission={ROUTES.question}>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={<AsyncQuestion />} />
        <Route path=":id" element={<AsyncQuestionForm />} />
        <Route path="new" element={<AsyncQuestionForm />} />
      </Route>
      <Route
        path={ROUTES.users}
        element={
          <PrivateRoute permission={ROUTES.users}>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={<AsyncUsers />} />
        <Route path=":id" element={<AsyncUsersForm />} />
        <Route path="new" element={<AsyncUsersForm />} />
      </Route>
      <Route
        path={ROUTES.sponsor}
        element={
          <PrivateRoute permission={ROUTES.sponsor}>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={<AsyncSponsorList />} />
        <Route path=":id" element={<AsyncSponsorForm />} />
        <Route path="new" element={<AsyncSponsorForm />} />
      </Route>
      <Route
        path={ROUTES.rpc}
        element={
          <PrivateRoute permission={ROUTES.rpc}>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={<AsyncRpcList />} />
        <Route path=":id" element={<AsyncRpcForm />} />
        <Route path="new" element={<AsyncRpcForm />} />
      </Route>
      <Route
        path={ROUTES.charger}
        element={
          <PrivateRoute permission={ROUTES.charger}>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={<AsyncChargerList />} />
        <Route path=":id" element={<AsyncChargerForm />} />
        <Route path="new" element={<AsyncChargerForm />} />
      </Route>
      <Route
        path={ROUTES.userChargerLog}
        element={
          <PrivateRoute permission={ROUTES.userChargerLog}>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={<AsyncLogChargerList />} />
        <Route path=":transactionId" element={<AsyncLogChargerDetails />} />
      </Route>
      <Route
        path={ROUTES.station}
        element={
          <PrivateRoute permission={ROUTES.station}>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={<AsyncStationList />} />
        <Route path=":id" element={<AsyncStationForm />} />
        <Route path="new" element={<AsyncStationForm />} />
      </Route>
      <Route
        path={ROUTES.outerStation}
        element={
          <PrivateRoute permission={ROUTES.outerStation}>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={<AsyncOuterStationList />} />
        <Route path=":id" element={<AsyncOuterStationForm />} />
        <Route path="new" element={<AsyncOuterStationForm />} />
      </Route>
      <Route
        path={ROUTES.pqrs}
        element={
          <PrivateRoute permission={ROUTES.pqrs}>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={<AsyncPqrsList />} />
        <Route path=":id" element={<AsyncPqrsForm />} />
      </Route>
      <Route
        path={ROUTES.settings}
        element={
          <PrivateRoute permission={ROUTES.settings}>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={<AsyncParameters />} />
        <Route path=":id" element={<AsyncParametersForm />} />
      </Route>
    </Route>
    <Route element={<Auth />}>
      <Route
        path={ROUTES.signIn}
        element={
          <LazyComponent>
            <AsyncSignIn />
          </LazyComponent>
        }
      />
      <Route
        path={ROUTES.forgotPassword}
        element={
          <LazyComponent>
            <Outlet />
          </LazyComponent>
        }
      >
        <Route index element={<AsyncForgotPassword />} />
        <Route path=":token" element={<AsyncChangePassword />} />
      </Route>
    </Route>
    <Route
      path="form"
      element={
        <LazyComponent>
          <AsyncFreeChargingForm />
        </LazyComponent>
      }
    />
    <Route path="*" element={<NotFound404 />} />
  </Routes>
);

export default Router;
