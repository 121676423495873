import { FILTER_TYPE } from "../../config/constants";

const config = {
  keyName: "id",
  getList: `/`,
  getByKey: "/",
  message: "message",
  fields: [
    {
      filter: true,
      key: "sponsorId",
      sorter: true,
      textKey: "sponsorName",
      title: "Patrocinador",
      type: "select",
      hide: true,
      features: {
        filtrable: true,
      },
      responsive: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
      configOptions: {
        map: (item) => ({ [item.id]: item.fullname }),
        url: "/sponsor/users",
      },
      rules: [{ required: true, message: "Este campo es obligatorio." }],
    },
    {
      title: "Aliado Comercial",
      key: "partnerId",
      textKey: "partnerName",
      sorter: true,
      filter: true,
      type: "select",
      hide: true,
      features: {
        filtrable: true,
      },
      responsive: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
      configOptions: {
        url: "/administration/partners",
        map: (item) => ({ [item.id]: item.fullname }),
      },
      rules: [{ required: true, message: "Este campo es obligatorio." }],
    },
    {
      title: "Estación",
      key: "stationId",
      textKey: "stationName",
      sorter: true,
      filter: true,
      filterType: FILTER_TYPE.options,
      type: "select",
      features: {
        filtrable: true,
      },
      hide: true,
      responsive: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
      configOptions: {
        url: "options/stations",
        map: (item) => ({ [item.value]: item.text }),
      },
    },
    {
      title: "Relay ID",
      key: "relayId",
      textKey: "relayName",
      type: "select",
      hide: true,
      responsive: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
      configOptions: {
        url: "options/getByName/CHARGER_RELAYS",
        map: (item) => ({ [item.value]: item.text }),
      },
    },
    {
      title: "Tipo de Conector",
      key: "connectorTypeId",
      textKey: "connectorTypeName",
      type: "select",
      hide: true,
      responsive: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
      configOptions: {
        url: "options/getByName/CHARGER_CONNECTORS_TYPE",
        map: (item) => ({ [item.value]: item.text }),
      },
    },
    {
      title: "Clientes",
      key: "personId",
      textKey: "personName",
      sorter: true,
      filter: true,
      type: "select",
      features: {
        filtrable: true,
      },
      hide: true,
      responsive: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
      configOptions: {
        url: "administration/clients",
        map: (item) => ({ [item.personId]: item.fullname }),
      },
    },
    {
      title: "Clientes",
      key: "userId",
      textKey: "userName",
      sorter: true,
      filter: true,
      type: "select",
      features: {
        filtrable: true,
      },
      hide: true,
      responsive: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
      configOptions: {
        url: "administration/clients",
        map: (item) => ({ [item.userId]: item.fullname }),
      },
    },
    {
      title: "Rango de fechas",
      key: "range",
      textKey: "range",
      sorter: true,
      filter: true,
      hide: false,
      type: "rangedate",
      responsive: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
      features: {
        placeholder: ["Fecha incial", "Fecha Final"],
      },
    },
  ],
};

export default config;
