import React from "react";
import { useNavigate } from "react-router-dom";
import { Form as FormAntd, Button, Row, Col } from "antd";
import PropTypes from "prop-types";
import { Title } from "../Shared";
import getForm from "./FormItemType";

const formItemLayout = (width) => ({
  labelCol: {
    xs: { span: 24 },
    sm: { span: width ? 24 - width : 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: width || 8 },
  },
});

const Form = ({
  buttonsAlign,
  colClassName,
  cols,
  extra,
  refForm,
  fields,
  hide,
  hideButtons,
  hideCancel,
  cancelText,
  hideTitle,
  initialValues,
  layout,
  loading,
  offsetButons,
  onCancel,
  onSubmit,
  onSubmited,
  submitButtonText = "Guardar",
  subTitle,
  title,
  verticalLabel,
}) => {
  const [form] = FormAntd.useForm();

  const navigate = useNavigate();

  const onSubmitForm = (values) => {
    onSubmit(values, onSubmited || (() => navigate(-1)));
  };
  let i = 0;

  return hide ? (
    ""
  ) : (
    <div className="form-container-generic">
      {hideTitle ? "" : <Title text={title} />}
      {<Title level={3} text={subTitle} />}
      <FormAntd
        form={refForm || form}
        onFinish={onSubmitForm}
        layout={layout || "horizontal"}
        initialValues={initialValues}
        autoComplete="off"
      >
        <Row gutter={8}>
          {fields
            .filter(
              (field) =>
                !(
                  field.hasOwnProperty("hidden") &&
                  field.hidden.includes("form")
                )
            )
            .map((field) => {
              return (
                <Col
                  offset={field.offset || 0}
                  span={field.col || cols || 24}
                  key={i++}
                  className={field.className || colClassName}
                  hidden={field.hide}
                  {...(field.responsive || {})}
                >
                  {getForm(
                    field,
                    form,
                    field.changeDependencies &&
                      fields.filter(
                        (e) => field.changeDependencies.indexOf(e.key) > -1
                      ),
                    {
                      ...(verticalLabel
                        ? null
                        : { ...formItemLayout(field.width) }),
                    }
                  )}
                </Col>
              );
            })}
        </Row>
        {extra ? (
          <Row gutter={8} type="flex" justify="space-around" align="middle">
            <Col span={24}>{extra}</Col>
          </Row>
        ) : (
          ""
        )}
        {hideButtons ? (
          ""
        ) : (
          <Row
            gutter={8}
            type="flex"
            justify={buttonsAlign || "start"}
            style={{ paddingTop: "20px" }}
          >
            {hideCancel ? (
              ""
            ) : (
              <Col
                sm={{ span: 3, offset: offsetButons || 3 }}
                xs={{ span: 24 }}
                className="custom-align-right"
              >
                <Button
                  size="large"
                  className="custom-full-width"
                  onClick={onCancel || (() => navigate(-1))}
                >
                  {cancelText || "Cancelar"}
                </Button>
              </Col>
            )}
            <Col sm={{ span: 3 }} xs={{ span: 24 }}>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                loading={loading}
                className="custom-full-width"
              >
                {submitButtonText}
              </Button>
            </Col>
          </Row>
        )}
      </FormAntd>
    </div>
  );
};

Form.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func,
  redirect: PropTypes.string,
  loading: PropTypes.bool,
  refForm: PropTypes.object,
  history: PropTypes.object,
  hideTitle: PropTypes.bool,
  hideButtons: PropTypes.bool,
  onCancel: PropTypes.func,
  cancelText: PropTypes.string,
  subTitle: PropTypes.string,
  offsetButons: PropTypes.number,
  onSubmited: PropTypes.func,
  colClassName: PropTypes.string,
  cols: PropTypes.number,
  hide: PropTypes.bool,
  extra: PropTypes.node,
  hideCancel: PropTypes.bool,
  buttonsAlign: PropTypes.string,
  verticalLabel: PropTypes.bool,
  layout: PropTypes.oneOf(["vertical", "horizantal"]),
  submitButtonText: PropTypes.string,
};

Form.defaultProps = {
  loading: false,
};

export default Form;
