import instance from "./instance";
import { downloadFilePromise } from "../utils/general";

const auth = {
  login: "signIn",
  changePass: "signIn/changePassword",
  recoverPassword: "signIn/recoverPassword",
  forgotPassword: "signIn/forgotPassword",
  validateToken: "signIn/validateToken",
  activeUser: "signUp/active",
  report: "summaryTransaction/download",
  allUsersDownload: "administration/users/download",
};

export const activeUserService = (data) => instance.post(auth.activeUser, data);

export const validateTokenService = () => instance.get(auth.validateToken);

export const authenticateService = (username, password, token) =>
  instance.post(auth.login, { username, password, token });

export const changePassword = ({
  currentPassword,
  newPassword,
  confirmPassword,
}) =>
  instance.post(auth.changePass, {
    currentPassword,
    newPassword,
    confirmPassword,
  });

export const recoverPasswordService = (data) =>
  instance.post(auth.recoverPassword, data);

export const forgotPasswordService = (data) =>
  instance.post(auth.forgotPassword, data);

export const downloadService = (params) =>
  downloadFilePromise({
    url: auth.report,
    params,
  });

export const allUsersDownloadService = (params) =>
  downloadFilePromise({
    url: auth.allUsersDownload,
    params,
  });
