import { STATUS_CODE } from "../config/constants";
import { getCities } from "../services/administration";

const defautlState = {
  cities: [],
};

const options = {
  state: defautlState,
  reducers: {
    SET_CLIENTS: (state, payload) => ({
      ...state,
      ...payload,
    }),
  },
  effects: {
    async loadCities() {
      try {
        const response = await getCities();
        const resData = response.data;
        if (resData.statusCode !== STATUS_CODE.SUCCESS) {
          Promise.reject(resData.message);
        }
        this.SET_CLIENTS({
          cities: resData.data,
        });
      } catch (error) {
        this.SET_CLIENTS(defautlState);
      }
    },
  },
};

export default options;
